:root {
  --text: #07133b;
  --background: #fffae5;
  --primary: #0f267b;
  --secondary: #77daaf;
  --accent: #5fc4dd;
  --backgroundFade: rgba(255, 250, 229, 0.3);
  --primaryFade: rgba(15, 38, 123, 0.2);
  --secondaryFade: rgba(119, 218, 175, 0.3);
  --accentFade: rgba(95, 196, 221, 0.3);
  --textFade: rgba(5, 13, 38, 0.6);
}

@import url('https://fonts.googleapis.com/css?family=Urbanist:700|Urbanist:500');

body {
  font-family: 'Urbanist';
  font-weight: 500;
  text-align: justify;
}

h1, h2, h3, h4, h5 {
  font-family: 'Urbanist';
  font-weight: 700;
  margin: 0;
  padding: 0;
}

html {font-size: 100%;} /* 16px */

h1 {font-size: 4.210rem; /* 67.36px */}

h2 {font-size: 3.158rem; /* 50.56px */}

h3 {font-size: 2.369rem; /* 37.92px */}

h4 {font-size: 1.777rem; /* 28.48px */}

h5 {font-size: 1.333rem; /* 21.28px */}

small {font-size: 0.750rem; /* 12px */}

button {
  border: none;
  font: inherit;
  border-radius: 0.75em;
  padding: 1em 2em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

html, body {
  margin: 0;
  padding: 0;
}