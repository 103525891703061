.Header-bg {
    background-color: var(--primary);
    overflow: hidden;
    height: 0;
    transition: all 0.5s;
    color: var(--background);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.Header-bg.expanded {
    height: 7.5vh;
    padding: 0.2em 1em;
}

.Header-bg.menu {
    height: 100vh;
    padding: 0.2em 1em;
    background-color: var(--primary);
}

.Title {
    width: 20vw;
    font-size: 2.369rem;
    font-weight: bold;
    text-align: center;
    color: var(--background);
}

.Page-buttons {
    width: 50vw;
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.Page-buttons button {
    width: auto;
    background-color: transparent;
    color: var(--background);
    transition: all 0.2s
}

.Contact-div {
    width: 20vw;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: flex-end;
}

.Contact-div a {
    background-color: transparent;
    color: var(--background);
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
}

.Contact-div a:hover, .Page-buttons button:hover {
    background-color: var(--secondary);
    color: var(--text);
    box-shadow: 0 0 3em 0 var(--secondary);
}

.Contact-div img {
    width: 2em;
    height: 2em;
    padding: 0.5em;
    transition: all 0.2s;
}

.Contact-div img:hover {
    filter: invert(1);
}

.Menu {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--primary);
    height: 0;
    transition: all 0.2s;
    overflow: hidden;
}

.Menu-button {
    background-color: transparent;
    color: var(--background);
    transition: all 0.2s;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 5em;
    width: 5em;
    display: none;
}

.Menu-button img {
    width: 4em;
    height: 4em;
}

.Menu-button.expanded{
    transform: rotate(135deg);
}

.Menu.expanded {
    height: 60vh;
    padding: 3em 1em;
}

.Menu-buttons {
    width: 75vw;
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: center;
    align-items: center;
}

.Menu-buttons button {
    width: 75vw;
    background-color: var(--accentFade);
    color: white;
    transition: all 0.2s;
    height: 5em;
    box-shadow: 0.1em 0.1em 0.5em 0em rgba(0, 0, 0, 0.77);
}

.Contact-div-menu {
    width: auto;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 2em;
    justify-content: flex-end;
    margin-bottom: 3em;
}

.Contact-div-menu a {
    background-color: transparent;
    color: var(--background);
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    box-shadow: 0.1em 0.1em 0.5em 0em rgba(0, 0, 0, 0.77);
    background-color: var(--accentFade);
}

.Contact-div-menu img {
    width: 3em;
    height: 3em;
    padding: 0.5em;
    transition: all 0.2s;
}

@media (max-width: 1200px) {
    .Title {
        font-size: 1.777rem;
    }
}

@media (max-width: 800px) {
    .Header-bg.expanded {
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.5em 1em;
        box-sizing: border-box;
    }

    .Menu-button {
        display: flex;
    }

    .Title {
        width: 100%;
    }

    .Page-buttons {
        display: none;
    }

    .Contact-div {
        display: none;
    }
}