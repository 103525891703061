.Wrapper {
    background: linear-gradient(0deg, rgba(7,19,59,1) 0%, rgba(15,38,123,1) 22%, rgba(15,38,123,1) 100%);
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    color: var(--text);
}

.Welcome {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 1.5em;
    color: var(--background);
    align-self: center;
    height: 100vh;
}

.Welcome h1 {
    display: inline-block;
    width: fit-content;
}

.Welcome h4 {
    color: var(--secondary);
}

.hidden {
    display: none;
}

.fade-out {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
}

.fade-in {
    animation: fadeIn 0.5s forwards
}

@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}

.Begin-button {
    width: auto;
    background-color: var(--background);
    transition: all 0.2s;
}

.Begin-button:hover {
    box-shadow: 0 0 3em 0 var(--background);
    margin-bottom: 0.2em;
    margin-top: -0.2em;
}

.Inner {
    background-color: var(--background);
    width: 99%;
    border-radius: 1em;
    display: flex;
    min-height: 25vh;
}



@media (max-width: 1200px) {
    .Welcome {
        text-align: left;
        padding: 2em;
    }
}

@media (max-width: 800px) {

    .Welcome {
        padding: 0 5em;
    }

    .Welcome h1 {
        font-size: 3.158rem;
    }
}